<template>
  <div class="ibox" ref="paymentConfiguration">
    <div class="ibox-title">
      <h4>Payment configuration</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content" style="display:none">
      <form id="config-payment-form" name="config-payment-form" role="form" action="#" method="post" @submit="onFormSubmit">
        <div class="form-group">
            <label for="mercanetAppReference">{{ $t('configuration.apis.mercanet.appReference') }}</label>
            <div>
              <input id="mercanetAppReference" type="text" :placeholder="$t('configuration.apis.mercanet.appReference_placeholder')" class="form-control" v-model="form.apis.mercanet.appReference" />
            </div>
        </div>
        <button type="submit" class="btn btn-primary block full-width ladda-button" data-style="zoom-in">{{ $t('configuration.form.button') }}</button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery'; 

@Component({
  components: { },
  
})
export default class Payment extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly config!: any

  form = {
    apis : {
      mercanet : {
        appReference : this.config.apis.mercanet.appReference
      }
    }
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=config-payment-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  
    enableIbox(this.$refs.paymentConfiguration as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.paymentConfiguration as HTMLElement);
  }

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/config/payment/update', this.form, options).then((response:any) => {
      
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>